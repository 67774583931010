<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="shortfallSettlementModal"
      :title="$t('Shorfall Settlement')"
      :buttons-hidden="true"
    >
      <div class="flex">
        <div class="w-full text-center text-black">
          <p>
            {{$t('Please, insert the received amount from the agent')}}
          </p>
        </div>
      </div>
      <div class="text-center">
        <div class="w-1/3 m-auto">
          <vs-input v-model="cashPaid" class="mt-4 w-full" name="cash" v-validate="`required|decimal|max_value:${agent && agent.cumulative_shortfall ? agent.cumulative_shortfall : 0}|min_value:0`"/>
          <p class="text-danger text-sm w-full" v-show="errors.has('cash')">{{ errors.first('cash') }}</p> 
        </div>
      </div>
      <div class="mt-4 text-center">
        <vs-button color="success" @click="sendSettlement()" class="active-btn btn w-1/2">{{ $t('Confirm') }}</vs-button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['shortfallSettlementModal', 'agent', 'userType', 'filters'],
  data () {
    return {
      cashPaid: ''
    }
  },
  methods: {
    closeModal () {
      this.cashPaid = ''
      this.$emit('shortfallSettlementModal', false)
    },
    sendSettlement () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const data = {
            agent: this.agent.id,
            amount: Number(this.cashPaid),
            type: 'settlement'
          }
          const query = `?create_khazna_transaction=${this.$store.state.AppActiveUser.userRole === 'head-of-fleet' ? 'true' : 'false'}`
          sendRequest(false, false, this, `api/v1/accounting/agent-shortfalls/${query}`, 'post', data, true,
            () => {
              this.$emit('filters', '')
              this.closeModal()
              this.cashPaid = ''
              this.$emit('agentShortfalls')
            })
        }
      })
    }
  },
  created () {
  },
  components: {
    ShipbluPrompt
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>
