<template>
  <div> 
    <div class="flex items-center justify-between">
      <div class="flex gap-3 items-center">
        <div class="relative m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
          v-for="agent in agentsWithShortfall" :key="agent.index" @click="changeFilter(agent.id)"
          v-bind:style="$route.query.filter && Number($route.query.filter) === Number(agent.id) ? 'background: red; color: white' : (!$route.query.filter || $route.query.filter === '') && agent.id === '' ? 'background: #1c5bfe; color: white;' : ''">
          <div class="text-sm capitalize">{{ agent.first_name }} {{agent.last_name}}</div>
          <div class="text-sm capitalize text-center" v-if="agent.cumulative_shortfall">{{ agent.cumulative_shortfall }}</div>
          <div class="absolute right-0 top-0 w-2 h-full rounded-4xl" :style="agent.id !== '' ? 'background:red' : 'background:#1c5bfe'"></div>
        </div>
      </div>
      <div class="text-center mt-4 grid grid-rows-2 grid-flow-col gap-4" v-if="$route.query.filter !== '' && $route.query.filter">
        <div>
          <vs-button class="ml-auto mt-2" @click="shortfallSettlementModal = true">{{$t('Settle Shortfall')}}</vs-button>
        </div>
        <div>
          <h3>HR ID : {{agents.filter(item => Number($route.query.filter) === Number(item.id)).length > 0 ? agents.filter(item => Number($route.query.filter) === Number(item.id))[0].hr_id : ''}}</h3>
        </div>
      </div>
    </div>
    <shipblu-table :sst="true" :data="allAgents" :tableLoader="tableLoader">
      <template slot="thead">
        <shipblu-th>{{$t('ID')}}</shipblu-th>
        <shipblu-th>{{$t('Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Issued By')}}</shipblu-th>
        <shipblu-th>{{$t('Transaction Date')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Reference Number')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="data[indextr].type === 'shortfall' ? 'red' : '#1c5bfe' ">
          <shipblu-td :data="data[indextr].id" :style="data[indextr].type === 'shortfall' ? 'color: red': 'color: #1c5bfe'">
            <span class="text-darkblue">{{ data[indextr].id }}</span>
          </shipblu-td>

          <shipblu-td :data="data[indextr].amount">
            <span :class="data[indextr].amount && data[indextr].amount > 0 ? '' : 'text-red'">
            {{ data[indextr].amount ? data[indextr].amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '' }}
            </span>
          </shipblu-td>

          <shipblu-td :data="data[indextr].created_by">
            {{ data[indextr].created_by.first_name + ' ' + data[indextr].created_by.last_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].created">
            <p>{{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].created">
            <p>{{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}</p>
            <p>{{ new Date(data[indextr].created).toLocaleTimeString('en-GB') }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].created">
            {{ data[indextr].reference}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].agent" style="color: green">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{$t('Approved')}}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination  :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <shortfall-settlement @agentShortfalls="agentShortfalls" :agent="agents.filter(item => Number($route.query.filter) === Number(item.id))[0]" :shortfallSettlementModal="shortfallSettlementModal" :userType="userType" @shortfallSettlementModal="shortfallSettlementModal = $event" :filters="filters" @filters="filters = $event"></shortfall-settlement>
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShortfallSettlement from './ShortfallSettlement.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      shortfallSettlementModal: false,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      khaznaTransactionModal: false,
      type: '',
      agents: [],
      warehousesDic: {},
      allAgents: [],
      filters: '',
      totalRows: 0,
      tableLoader: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      agentsWithShortfall: [],
      userType:'finance'
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          filterTab: this.$route.query.filterTab,
          filter: this.filters,
          page: this.currentPage
        }
      }).catch(() => {})
      this.agentShortfalls()
    },
    'filters' (val) {
      this.currentPage = 1
      this.$router.push({
        query: {
          filter: val,
          page: this.currentPage,
          filterTab: this.$route.query.filterTab
        }
      }).catch(() => {})
    }
  },
  methods: {
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
      this.agentShortfalls()
    },
    changeFilter (value) {
      this.filterValue = value
      this.filters = this.filterValue
      if (this.filterValue === 'all') {
        this.filters = ''
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          filter: this.filters,
          filterTab: this.$route.query.filterTab,
          page: this.currentPage
        }
      }).catch(() => {})
      this.agentShortfalls()
    },
    agentShortfalls () {
      this.tableLoader = true
      const query = `?primary_warehouse=${this.$route.params.warehouseID}&agent_id=${this.$route.query.filter ? this.$route.query.filter : ''}&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/accounting/agent-shortfalls/${query}`, 'get', null, true,
        (response) => {
          this.allAgents = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.loadAgents()
        }
      )
    },
    loadAgents () {
      sendRequest(false, false, this, `api/v1/warehouse/${this.$route.params.warehouseID}/agents/customer_agent/?limit=100&include_deleted=true&cumulative_shortfall_gt=0`, 'get', null, true, 
        (response) => {
          this.agents = response.data.results
          this.agentsWithShortfall = this.agents
          this.agentsWithShortfall.unshift({
            first_name: 'All',
            id: ''
          })
        }
      )
    },
    openKhaznaModal (type) {
      this.type = type
      this.khaznaTransactionModal = true
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    ShortfallSettlement
  },
  created () {
    common.manageLoadWarehouses(this.formatWarehouses, this)
    this.offset = (this.currentPage - 1) * this.maximumItems
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
