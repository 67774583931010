<template>
  <div class="shipments-page">
    <AgentShortfall></AgentShortfall>
  </div>
</template>

<script>
import AgentShortfall from '../../headOfFleet/components/AgentShortfall.vue'

export default {
  components: {
    AgentShortfall
  }
}
</script>
